

















































































import { _fetch } from '@/api/v3'
import { uiStore } from '@/store'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    AppBtn: () => import('./AppBtn.vue'),
  },
})
export default class MobileApps extends Vue {
  private releases: Record<string, Array<{ type: string; url: string }>> = {}

  private get hasAppsLinks () {
    return this.hasDesktopAppsLinks || this.hasMobileAppsLinks
  }

  private get hasDesktopAppsLinks () {
    return (
      this.linuxLink ||
      this.macLink ||
      this.winExeLink ||
      this.winMsiLink
    )
  }

  private get hasMobileAppsLinks () {
    return (
      this.androidLink ||
      this.appStoreLink ||
      this.googlePlayLink ||
      this.ruMarketLink
    )
  }

  private get androidLink () { return this.releases?.android?.[0]?.url }
  private get appStoreLink () { return window.FEATURES.ios_app }
  private get googlePlayLink () { return window.FEATURES.android_app }
  private get linuxLink () { return this.releases?.linux?.[0]?.url }
  private get macLink () { return this.releases?.mac?.[0]?.url }
  private get ruMarketLink () { return window.FEATURES.rumarket_app }

  private get winExeLink () {
    return this.releases?.win?.find(item => item.type === 'exe')?.url
  }

  private get winMsiLink () {
    return this.releases?.win?.find(item => item.type === 'msi')?.url
  }

  async mounted () {
    this.releases = await _fetch('/api/v4/releases/') ?? {}
  }

  close () {
    uiStore.actions.hideModal()
  }
}
